<script lang="ts">
let count = 0;
</script>

<script setup lang="ts">
const emit = defineEmits(["update:modelValue", "blur"]);

const props = withDefaults(
  defineProps<{
    id?: string;
    modelValue: string;
    name?: string;
    autocomplete?: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    inputError?: boolean;
    inputErrorMessage?: string | Ref<string>;
    type?:
      | "date"
      | "datetime-local"
      | "email"
      | "month"
      | "number"
      | "password"
      | "search"
      | "tel"
      | "text"
      | "time"
      | "url"
      | "week";
    focus?: boolean;
    disabled?: boolean;
    min?: string;
    max?: string;
    margin?: number;
    isValid?: boolean;
    allowWarning?: boolean;
  }>(),
  {
    id: "textinput-" + count++,
    type: "text",
    inputError: false,
    disabled: false,
    margin: 0,
    allowWarning: false,
  },
);
const { id, modelValue, inputError, inputErrorMessage, focus, disabled } =
  toRefs(props);

const inputRef = ref();
if (focus.value) {
  useFocus(inputRef, { initialValue: true });
}

const value = computed({
  get: () => modelValue.value,
  set: (newValue) => emit("update:modelValue", newValue),
});
</script>

<template>
  <div>
    <label
      v-if="!!label"
      class="text-scheppach-primary-500 text-sm md:text-base"
      :for="id"
    >
      {{ label }}{{ required ? " *" : "" }}
    </label>
    <input
      :id="id"
      v-model="value"
      :required="required"
      :type="type"
      :name="name"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :disabled="disabled"
      :minlength="min"
      :maxlength="max"
      class="sw-search-input placeholder-text-scheppach-neutral-500 c-scheppach-primary-500 bg-scheppach-neutral-50 p-3 outline-none w-full b-b"
      :class="[
        disabled ? 'bg-scheppach-primary-50! cursor-no-drop' : '',
        !inputError ? 'b-scheppach-primary-700' : '',
        isValid ? 'b-scheppach-success-500' : '',
        inputError && allowWarning ? 'b-orange' : 'b-scheppach-error-500',
        true ? `mb-${margin}` : ``,
      ]"
      @blur="emit('blur')"
    />
    <span
      v-if="inputError && !!inputErrorMessage"
      class="pt-1 text-sm"
      :class="[
        inputError && allowWarning ? 'c-orange' : 'c-scheppach-error-500',
      ]"
    >
      {{ inputErrorMessage }}
    </span>
  </div>
</template>
